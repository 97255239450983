<script>
import apiTool from '@/command/apiTool'
import { postAction, getAction, putAction } from '@/command/netTool'
import request from '@/utils/request'
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formData: {},
      activityBackground: []
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (!this.init && newValue && typeof newValue == 'string') {
          this.init = true
          const keys = typeof newValue == 'string' ? newValue.split(',') : []
          this.getList({ select: keys })
        }
      },
      immediate: false
    },
    activityBackground(newValue) {
      this.$emit('change', newValue)
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList({ select = [] } = {}) {
      getAction('/api/base/farmBackgroundPicture/list').then(e => {
        this.activityBackground = e.data.map(e => ({
          ...e,
          select: select.indexOf(e.id) !== -1
        }))
      })
    },
    getValue() {
      return this.activityBackground
    },
    onDelete(item) {
      request({
        url: `/base/farmBackgroundPicture/${item.id}`,
        method: 'DELETE'
      }).then(() => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    onEdit(item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.onOpenDrawer()
    },
    onAdd() {
      this.formData = {}
      this.onOpenDrawer()
    },
    radioSelect(data) {
      if (this.item.is_show) return
      // () => (e.select = !e.select)
      this.getValue().forEach(element => {
        element.select = false
      })
      data.select = !data.select
    },
    renderValue() {
      return (
        <div class="action-upload-img-group">
          {this.getValue().map(e => {
            const isSelect = e.select
            return (
              <aside style="text-align: center">
                <div
                  onClick={() => this.radioSelect(e)}
                  class={`action-upload-item-${isSelect ? 'select ' : 'normal'}`}
                  style={{ backgroundImage: 'url(' + e.image2 + ')' }}
                >
                  {this.item.is_show && (
                    <div class="bg-box">
                      <a-icon type="form" onClick={() => this.onEdit(e)} />
                      {Number(e.referenceTimes) == 0 && (
                        <a-icon style="margin-top: 15px" type="delete" onClick={() => this.onDelete(e)} />
                      )}
                    </div>
                  )}
                </div>
                {this.item.is_show && <span>{e.referenceTimes}次引用</span>}
              </aside>
            )
          })}
        </div>
      )
    },
    onOpenDrawer() {
      apiTool.showDrawer({
        title: '活动背景',
        formData: this.formData,
        width: '600px',
        success: ({ data, setHidden }) => {
          console.log(data, '----data-----')
          if (!data.image2 || !data.image3) {
            this.$message.error('必须上传两张图片')
            return
          } else if (data.id) {
            putAction('/base/farmBackgroundPicture', data).then(() => {
              this.$message.success('编辑活动背景图片成功')
              this.getList()
              this.formData = {}
              setHidden()
            })
          } else {
            postAction('/base/farmBackgroundPicture', data).then(() => {
              this.$message.success('添加活动背景图片成功')
              this.getList()
              this.formData = {}
              setHidden()
            })
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit
            }
          ]
        },
        form: [
          {
            type: 'iconUpload',
            key: 'a1',
            typeData: [
              {
                maxLength: 1,
                key: 'image2',
                desc: '@2x   xhdpi <br/> 上传尺寸750*480px'
              },
              {
                maxLength: 1,
                key: 'image3',
                desc: '@3x   xhdpi <br/> 上传尺寸1125*720px'
              }
            ]
          }
        ]
      })
    },
    renderUploader() {
      return (
        <div class="action-uploader" onClick={() => this.onAdd()}>
          <a-icon type="plus" />
          上传
        </div>
      )
    }
  },
  render() {
    return (
      <div class="action-upload-main">
        {this.renderValue()}
        {this.renderUploader()}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.bg-box {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.action-upload-main {
  display: flex;
  flex-wrap: wrap;
}
.action-upload-img-group {
  display: flex;
  flex-wrap: wrap;
  img {
    width: 80px;
    height: 100px;
  }
}
.action-upload-item-normal {
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;

  &:hover {
    .bg-box {
      display: flex;
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;

      .anticon-form,
      .anticon-delete {
        color: #ddd;
      }
    }
  }
}
.action-upload-item-select:extend(.action-upload-item-normal) {
  border: 3px solid rgba(44, 140, 240, 1);
  border-radius: 3px;
}
.action-uploader {
  background: #f5f5f5;
  width: 80px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
