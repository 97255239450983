<script>
import { Switch } from 'ant-design-vue'
export default {
  props: ['item', 'value', 'mode'],
  mounted() {
    if (this.value == null) {
      this.$emit('change', '1')
    }
  },
  methods: {
    onChange(data) {
      if (this.item.onChange) {
        this.item.onChange(data)
      }
      this.$emit('change', data ? '0' : '1')
    }
  },
  render() {
    return (
      <Switch
        disabled={this.mode == 'detail' || this.item.disabled}
        checked={this.value == '0'}
        defaultChecked={this.value == '0'}
        onChange={data => this.onChange(data)}
        checked-children={this.item.showText ? this.item.showText[0] : ''}
        un-checked-children={this.item.showText ? this.item.showText[1] : ''}
      />
    )
  }
}
</script>
