<script>
import { Input } from 'ant-design-vue'
export default {
  props: ['item', 'value', 'mode'],
  data() {
    return {
      data: '',
    }
  },
  watch: {
    value() {
      if (this.data === this.value) return
      this.data = this.value || ''
    },
  },
  mounted() {
    this.data = this.value || ''
  },
  methods: {
    onChange(e) {
      if (this.item.onChange) {
        this.item.onChange(e)
      }
      this.$emit('change', String(e))
    },
  },
  render() {
    return (
      <div>
        <div class="form-input">
          <Input
            props={this.item.props}
            disabled={this.item.disabled}
            value={this.value}
            placeholder={this.item.placeholder ? this.item.placeholder : '请输入' + (this.item.name || this.item.label)}
            onChange={(e) => this.onChange(e.target.value)}
            style={{ width: '100%', ...this.item.styles }}
            maxLength={this.item.maxLength ?? 40}
          />
          {this.item.desc && <div>{this.item.desc}</div>}
          {this.item.showCount && (
            <div class="showCount">
              {this.data.length || 0}/{this.item.maxLength || 40}
            </div>
          )}
        </div>
        {this.item.remark && <div class="form-input-remark">{this.item.remark}</div>}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.form-input {
  display: flex;
  align-items: center;
  margin-top: 3px;
  position: relative;
  .showCount {
    position: absolute;
    bottom: 0px;
    right: 8px;
    line-height: 32px;
    font-size: 14px;
    color: #ccc;
    user-select: none;
  }
  div {
    margin-left: 10px;
    color: #a0a0a0;
    white-space: nowrap;
  }
}

/deep/.ant-input[disabled] {
  color: rgba(0, 0, 0, 1);
  background-color: #fff;
  cursor: not-allowed;
  opacity: 1;
}
</style>
