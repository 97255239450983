<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  render() {
    return (
      <div class="form-text" style={{ justifyContent: this.item.align, ...this.item.styles }}>
        {this.item.text()}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.form-text {
  min-width: 30px;
  color: #515a6e;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f5f5f5;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 20px;
  margin-bottom: 20px;
  p {
    margin: 0;
  }
}
</style>
