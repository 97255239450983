import { getAction } from '@/command/netTool'

const textFiles = {
  state: {
    byProductType: [],
    byGoodsType: [],
    byTicketType: [],
    byBrandType: [],
    byStarHotelType: [],
    byWindowType: [],
    byBedType: [],
    byWIFIType: [],
    byWhetherType: [],
    byExtraBedType: [],
    byBroadbandType: []
  },

  mutations: {
    SET_PRODUCT_TYPE(state, data) {
      state.byProductType = data
    },
    SET_GOODS_TYPE(state, data) {
      state.byGoodsType = data
    },
    SET_Ticket_TYPE(state, data) {
      state.byTicketType = data
    },
    SET_Brand_TYPE(state, data) {
      state.byBrandType = data
    },
    SET_StarHotel_TYPE(state, data) {
      state.byStarHotelType = data
    },
    SET_Window_TYPE(state, data) {
      state.byWindowType = data
    },
    SET_Bed_TYPE(state, data) {
      state.byBedType = data
    },
    SET_WIFI_TYPE(state, data) {
      state.byWIFIType = data
    },
    SET_Whether_TYPE(state, data) {
      state.byWhetherType = data
    },
    SET_ExtraBed_TYPE(state, data) {
      state.byExtraBedType = data
    },
    SET_Broadband_TYPE(state, data) {
      state.byBroadbandType = data
    }
  },

  actions: {
    //* 针对产品类型
    getProductType({ commit }) {
      getAction('/common/order/queryOrderFormTypeList', {}, '/api').then(res => {
        const { data } = res
        let arr = data.map(e => {
          return {
            ...e,
            name: e.desc,
            text: e.desc,
            value: e.code
          }
        })
        commit('SET_PRODUCT_TYPE', arr)
      })
    },
    //* 商品类型
    getGoodsType({ commit }) {
      getAction('/market/farmBanner/getGoodsTypeList', {}, '/api').then(res => {
        const { data } = res
        let arr = data.map(e => {
          return {
            ...e,
            name: e.name,
            text: e.name,
            value: e.type
          }
        })
        commit('SET_GOODS_TYPE', arr)
      })
    },
    //* 票种
    getTicketType({ commit }) {
      getAction(
        '/admin/dict/codes?codes=Ticket_type,brand,star_hotel,window,bed_type,WIFI,Whethersmokingisprohibited,Extrabedsareavailable,Broadband_situation',
        {},
        '/bpi'
      ).then(res => {
        let data = res.data
        let {
          Ticket_type,
          brand,
          star_hotel,
          window,
          bed_type,
          WIFI,
          Whethersmokingisprohibited,
          Extrabedsareavailable,
          Broadband_situation
        } = data
        let arr = Ticket_type.map(e => {
          return {
            ...e,
            name: e.name,
            text: e.name,
            value: e.id
          }
        })
        let brr = brand.map(e => {
          return {
            ...e,
            name: e.name,
            text: e.name,
            value: e.id
          }
        })
        let crr = star_hotel.map(e => {
          return {
            ...e,
            name: e.name,
            text: e.name,
            value: e.id
          }
        })
        let drr = window.map(e => {
          return {
            ...e,
            name: e.name,
            text: e.name,
            value: e.id
          }
        })
        let err = bed_type.map(e => {
          return {
            ...e,
            name: e.name,
            text: e.name,
            value: e.id
          }
        })
        let frr = WIFI.map(e => {
          return {
            ...e,
            name: e.name,
            text: e.name,
            value: e.id
          }
        })
        let grr = Whethersmokingisprohibited.map(e => {
          return {
            ...e,
            name: e.name,
            text: e.name,
            value: e.id
          }
        })
        let hrr = Extrabedsareavailable.map(e => {
          return {
            ...e,
            name: e.name,
            text: e.name,
            value: e.id
          }
        })
        let irr = Broadband_situation.map(e => {
          return {
            ...e,
            name: e.name,
            text: e.name,
            value: e.id
          }
        })
        commit('SET_Ticket_TYPE', arr)
        commit('SET_Brand_TYPE', brr)
        commit('SET_StarHotel_TYPE', crr)
        commit('SET_Window_TYPE', drr)
        commit('SET_Bed_TYPE', err)
        commit('SET_WIFI_TYPE', frr)
        commit('SET_Whether_TYPE', grr)
        commit('SET_ExtraBed_TYPE', hrr)
        commit('SET_Broadband_TYPE', irr)
      })
    }
  }
}

export default textFiles
