import { Modal, Drawer, message } from 'ant-design-vue'
import Vue from 'vue'
import FormView from '../components/FormView'
import MapView from '../components/MapView'
import MapMarkersView from '../components/MapMarkersView'
import PopButton from '../components/PopButton'
import store from '@/store'
import router from '@/router'
import { delCookie } from '@/utils/cookie'
/**
 * 显示弹窗
 */
export const showModal = function showModal({
  title,
  form,
  typeData,
  mode,
  success,
  formData,
  view,
  width,
  viewProps = {},
  foot = () => { },
  footer = true
}) {
  const body = document.body
  const div = document.createElement('div')
  body.appendChild(div)
  new Vue({
    el: div,
    data() {
      return {
        visible: true
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach(e => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        this.$refs._form.sumbit()
      },
      onSuccess(data) {
        console.log('表单成功', data)
      },
      renderView() {
        const View = view
        return (
          <View
            ref={'_form'}
            onSuccess={data => success({ data, setHidden: this.onCancel })}
            props={viewProps}
            formData={formData}
          />
        )
      }
    },
    render() {
      return (
        <Modal
          title={title}
          width={width}
          visible={this.visible}
          onCancel={this.onCancel}
          onOk={this.onOk}
          maskClosable={false}
          footer={footer && foot?.({ close: this.onCancel })}
        >
          {view ? (
            this.renderView()
          ) : (
            <FormView
              ref={'_form'}
              data={form}
              layout={'horizontal'}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              typeData={typeData}
              form={formData}
              mode={mode}
              onSuccess={data => success({ data, setHidden: this.onCancel })}
            />
          )}
        </Modal>
      )
    }
  })
}

/**
 * 显示抽屉
 */
export const showDrawerTable = function showDrawerTable({
  title,
  form,
  typeData,
  mode,
  success,
  formData,
  width,
  view,
  viewProps = {},
  formProps = {},
  foot = []
}) {
  const body = document.body
  const div = document.createElement('div')
  div.style.zIndex = 9999
  body.appendChild(div)
  const vm = new Vue({
    el: div,
    data() {
      return {
        visible: true,
        update: false
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach(e => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        this.$refs._form.sumbit()
      },
      onSuccess(data) {
        console.log('表单成功', data)
      },
      renderFoot() {
        const footData = typeof foot == 'function' ? foot({ close: this.onCancel, submit: this.onOk }) : foot
        return (
          <div class="drawer-modal-foot">
            {footData.map(e => {
              return <PopButton item={e} onClick={e.onClick} />
            })}
          </div>
        )
      },
      renderView() {
        const View = view
        return (
          <View
            ref={'_form'}
            typeData={this.getTypeData()}
            onSuccess={data => success({ data, setHidden: this.onCancel })}
            props={viewProps}
          />
        )
      },
      getTypeData() {
        return typeof typeData == 'function' ? typeData() : typeData
      },
      renderBody() {
        return (
          <div class="drawer-modal-body">
            {view ? (
              this.renderView()
            ) : (
              <FormView
                ref={'_form'}
                data={form}
                cols={24}
                typeData={this.getTypeData()}
                form={formData}
                mode={mode}
                props={formProps}
                onSuccess={data => success({ data, setHidden: this.onCancel })}
              />
            )}
          </div>
        )
      }
    },
    render() {
      return (
        <Drawer
          visible={this.visible}
          onClose={this.onCancel}
          onOk={this.onOk}
          placement={'right'}
          width={width}
          closable={false}
          class="drawer-modal-main-table-box"
          headerStyle={{
            height: '0px'
          }}
        >
          <div class="drawer-modal-main-table">
            {foot && this.renderFoot()}
            {this.visible && this.renderBody()}
          </div>
        </Drawer>
      )
    }
  })
  return vm
}

/**
 * 显示抽屉
 */
export const showDrawer = function showDrawer({
  title,
  form,
  typeData,
  mode,
  success,
  formData,
  width,
  view,
  viewProps = {},
  formProps = {},
  foot = [],
  targetClass = 'ant-modal-root',
  onHidden
}) {
  const body = document.body
  const div = document.createElement('div')
  div.style.zIndex = 9999
  body.appendChild(div)
  const vm = new Vue({
    el: div,
    data() {
      return {
        visible: true,
        update: false,
        loading: false
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName(targetClass)
          Array.from(dom).forEach(e => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        onHidden && onHidden()
        this.loading = true
        this.$refs._form.sumbit()
        setTimeout(() => {
          this.loading = false
        }, 1500)
      },
      onSuccess(data) {
        console.log('表单成功', data)
      },
      renderFoot() {
        const footData = typeof foot == 'function' ? foot({ close: this.onCancel, submit: this.onOk }) : foot
        return (
          <div class="drawer-modal-foot">
            {footData.map(e => {
              return <PopButton item={{ loading: e.type === 'primary' && this.loading, ...e }} onClick={e.onClick} />
            })}
          </div>
        )
      },
      renderView() {
        const View = view
        return (
          <View
            ref={'_form'}
            typeData={this.getTypeData()}
            onSuccess={data => success({ data, setHidden: this.onCancel })}
            props={viewProps}
          />
        )
      },
      getTypeData() {
        return typeof typeData == 'function' ? typeData() : typeData
      },
      renderBody() {
        return (
          <div class="drawer-modal-body">
            {view ? (
              this.renderView()
            ) : (
              <FormView
                ref={'_form'}
                data={form}
                cols={24}
                typeData={this.getTypeData()}
                form={formData}
                mode={mode}
                props={formProps}
                onSuccess={data => success({ data, setHidden: this.onCancel })}
              />
            )}
          </div>
        )
      }
    },
    render() {
      return (
        <Drawer
          title={title}
          visible={this.visible}
          onClose={this.onCancel}
          onOk={this.onOk}
          placement={'right'}
          width={width}
          class={targetClass}
        >
          <div class="drawer-modal-main">
            {foot && this.renderFoot()}
            {this.visible && this.renderBody()}
          </div>
        </Drawer>
      )
    }
  })
  return vm
}

// 打开地图
export const openMap = function openMap({ title, value = [], onSuccess, id }) {
  const body = document.body
  const div = document.createElement('div')
  body.appendChild(div)
  new Vue({
    el: div,
    data() {
      return {
        visible: true
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach(e => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        const data = this.$refs.map.getData()
        if (data) {
          onSuccess && onSuccess(data)
          this.onCancel()
        } else {
          message.error('请在地图上选择地址')
        }
      }
    },
    render() {
      return (
        <Modal
          title={title}
          okText="确定"
          cancelText="取消"
          visible={this.visible}
          onCancel={this.onCancel}
          onOk={this.onOk}
        >
          <MapView ref="map" value={value} id={id} />
        </Modal>
      )
    }
  })
}

// 打开地图标记
export const openMapMarkers = function openMapMarkers({ title, value }) {
  const body = document.body
  const div = document.createElement('div')
  body.appendChild(div)
  new Vue({
    el: div,
    data() {
      return {
        visible: true
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach(e => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        this.onCancel()
      }
    },
    render() {
      return (
        <Modal
          title={title}
          okText="确定"
          cancelText="取消"
          visible={this.visible}
          onCancel={this.onCancel}
          onOk={this.onOk}
        >
          <MapMarkersView ref="map" value={value} />
        </Modal>
      )
    }
  })
}

export const validateNumber = (rule, value, callback) => {
  if (value && !/^[0-9]*$/.test(Number(value))) {
    callback('请输入数字')
  } else {
    callback()
  }
}

export const jumpLogin = () => {
  store.dispatch('Logout').then(() => {
    delCookie('access_token')
    router.push({ name: 'login' })
  })
}

/**
 * 获取vip金额
 */
export const getVipPrice = result => {
  const getVipPic = function (pic1, pic2, index) {
    let price = Math.round(Number(pic1 - (index * (pic1 - pic2)) / 6) * 100) / 100
    return price
  }
  const fill = Array(6).fill('')
  fill.forEach((e, index) => {
    // eslint-disable-next-line prettier/prettier
    const data = result.vipPrice && result.salePrice ? getVipPic(result.salePrice, result.vipPrice, index + 1) : '-'
    result[`v${index + 1}`] = data
  })
  return result
}

// 获取筛选按钮
export const getButtonName = () => {
  const lButton = JSON.parse(localStorage.getItem('button')) || {}
  const currentPath = lButton[location.pathname]
  if (currentPath) {
    return currentPath.map(e => e.name)
  } else {
    return []
  }
}

export const uploadFile = ({ success } = {}) => {
  const input = document.createElement('input')
  input.type = 'file'
  input.multiple = 'multiple'
  input.accept = '.png,.jpeg,.jpg'
  input.onchange = async function (e) {
    let imgUrls = []
    const { token, url } = JSON.parse(localStorage.getItem('qiniu')) || {}
    let files = e.target.files
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = function (e) {
        let image = new Image();
        image.src = window.URL.createObjectURL(files[i]);
        image.onload = function () {
          // 限制图片尺寸 w 宽 h 高
          let w = 9000;
          let h = 9000;
          // 限制图片大小 MB
          let size = 15;
          console.log('图片信息',(files[i].size / (1024 * 1024)).toFixed(2) + 'MB',image.width,image.height)
          if (image.width <= w && image.height <= h && files[i].size <= 1024 * 1024 * size) {
            imgUrls.push(files[i])
          }
        };
      };
    }
    setTimeout(async () => {
      if (imgUrls.length != files.length) {
        message.error("图片宽高必须小于9000PX,文件大小必须小于15MB");
      } else {
        let newImgUrls = []
        for (let i = 0; i < imgUrls.length; i++) {
          const formData = new FormData()
          formData.append('file', files[i])
          formData.append('token', token)
          let e = await fetch('//upload.qiniup.com/', {
            method: 'POST',
            body: formData,
            headers: {
              token
            }
          }).then(e => e.json())
          newImgUrls.push(url + e.hash)
        }
        success(newImgUrls)
      }
    }, 500);
    // success(imgUrls)
  }
  input.click()
}

/**
 * 时间格式转化，获取前n天，后n天
 * params: {
 *  day: 前后n天 0为当天，正值表示未来n天，负值表示过去n天
 *  fmt: 时间转化的格式
 *  time: 传入时间，默认为当前时间
 * }
 */
export const recentTime = (day, fmt, time) => {
  //获取当前时间的毫秒值
  let now = (time ? new Date(time) : new Date()).getTime()
  // 获取前后n天时间
  let recent = new Date(now + day * 24 * 60 * 60 * 1000)

  // key：正则匹配表达式，value：对应的时间、日期
  let fmtObj = {
    'M+': recent.getMonth() + 1, //月份
    'd+': recent.getDate(), //日
    'h+': recent.getHours(), //时
    'm+': recent.getMinutes(), //分
    's+': recent.getSeconds() //秒
  }
  // 获取匹配年份替换
  if (/(y+)/.test(fmt)) {
    //RegExp.$1 匹配结果，替换成对应的长度。如：yyyy就替换成整个年份2021，yy就替换成后两位21，以此类推
    fmt = fmt.replace(RegExp.$1, (recent.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let key in fmtObj) {
    if (new RegExp(`(${key})`).test(fmt)) {
      //日期，时、分、秒替换，判断fmt是否需要补0，如：yyyy-M-d h:m:s 不补0,yyyy-MM-dd hh:mm:ss 则自动补0
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? fmtObj[key] : ('00' + fmtObj[key]).substr(('' + fmtObj[key]).length)
      )
    }
  }
  return fmt
}

export const getSzncH5Url = () => {
  if(window.location.origin.includes('localhost')){
    return 'http://127.0.0.1:7062/#'
  } else if(window.location.origin.includes('.work')){
    return `https://sznch5.ztesa.xyz/${localStorage.getItem('appCode').toLowerCase()}/#`
  } else {
    return `https://nbsznc.com/${localStorage.getItem('appCode').toLowerCase()}/#`
  }
}

export default {
  showModal,
  showDrawer,
  showDrawerTable,
  openMap,
  openMapMarkers,
  validateNumber,
  jumpLogin,
  getVipPrice,
  recentTime,
  getButtonName,
  uploadFile,
  getSzncH5Url
}
