<script>
import { DatePicker } from 'ant-design-vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import moment from 'moment'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => []
    },
    getValue: {
      type: Function,
      default: null
    },
    setValue: {
      type: Function,
      default: null
    }
  },
  methods: {
    getData() {
      if (this.item.keys) {
        return this.item.keys.map(e => {
          const value = this.getValue({ key: e })
          if (value) {
            return moment(value)
          } else {
            return value
          }
        })
      } else {
        return this.value
      }
    },
    onChange(times) {
      if (this.item.onChange) {
        this.item.onChange(times)
      }
      if (this.item.keys && times.length > 0) {
        this.item.keys.forEach((e, i) => {
          if (this.item.showTime) {
            this.setValue(e, moment(times[i]).format('YYYY-MM-DD HH:mm:ss'))
          } else if (this.item.showTimeNo) {
            this.setValue(e, moment(times[i]).format('YYYY-MM-DD 00:00:00'))
          } else {
            this.setValue(e, moment(times[i]).format('YYYY-MM-DD'))
          }
        })
      } else {
        this.$emit('change', times)
      }
    }
  },
  render() {
    return (
      <DatePicker.RangePicker
        showTime={this.item.showTime}
        disabled={this.item.disabled}
        ranges={this.item.ranges ? this.item.ranges : {}}
        allowClear={false}
        onChange={this.onChange}
        value={this.getData()}
        style={{ width: '100%' }}
        placeholder={['开始日期', '结束日期']}
        locale={locale}
      ></DatePicker.RangePicker>
    )
  }
}
</script>
