<script>
import { Input } from 'ant-design-vue'
export default {
  props: ['value', 'id'],
  data() {
    return {
      lnglat: ''
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.map = new AMap.Map(this.$refs.map, {
      resizeEnable: true,
      zoom: 11,
      center: [121.6216, 29.859515],
      viewMode: '3D'
    })
    let that = this
    // eslint-disable-next-line no-undef
    this.clickListener = AMap.event.addListener(this.map, 'click', function(e) {
      that.addMarker(e.lnglat)
    })
    // eslint-disable-next-line no-undef
    let autoCompleteComponent = new AMap.Autocomplete({
      input: 'tipinput'
      // outPutDirAuto: true
    })
    // eslint-disable-next-line no-undef
    AMap.event.addListener(autoCompleteComponent, 'select', data => {
      that.addMarker(data.poi.location)
    })
    if (this.id) {
      let lnlaObj = this.value.find(item => item.id == this.id)
      if (lnlaObj) {
        this.map.setCenter([lnlaObj.lng, lnlaObj.lat])
      }
    }

    this.addTitle()
  },
  methods: {
    addTitle(newValue) {
      let marksValue = newValue ? newValue : this.value
      const markerList = marksValue.map(
        item =>
          // eslint-disable-next-line no-undef
          new AMap.Marker({
            position: [item.lng, item.lat],
            icon: item.icon || 'https://webapi.amap.com/theme/v1.3/markers/n/mark_bs.png'
          })
      )
      this.map.add(markerList)
    },
    addMarker(lnglat) {
      this.lnglat = {
        ...lnglat,
        id: this.id || Date.now()
      }
      this.map.clearMap()
      if (this.id) {
        let index = this.value.findIndex(e => e.id == this.id)
        let newValue = [...this.value]
        newValue.splice(index, 1)
        this.$nextTick(() => {
          this.addTitle(newValue)
        })
      } else {
        this.addTitle()
      }
      this.map.setCenter([lnglat.lng, lnglat.lat])
      let icon = new AMap.Icon({
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
        imageSize: new AMap.Size(20, 40)
      })
      this.map.add(
        // eslint-disable-next-line no-undef
        new AMap.Marker({
          position: [lnglat.lng, lnglat.lat],
          icon
        })
      )
    },
    getData() {
      return this.lnglat
    }
  },
  render() {
    return (
      <div class="mapview-main">
        <Input id="tipinput" enter-button="搜索" ref="input" placeholder="请输入要搜索地址" />
        <div ref="map" style={{ display: 'flex', height: '300px' }} />
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.mapview-main {
  display: flex;
  flex-direction: column;
  & > div {
    margin-top: 20px;
  }
}
</style>
