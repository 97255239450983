<script>
import { Tabs, Button } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    onClick(key) {
      if (this.item.onClick) {
        this.item.onClick(this.item.typeData[key])
      }
    }
  },
  render() {
    return (
      <Tabs defaultActiveKey={0} style={{ width: '115%' }} onTabClick={key => this.onClick(key)}>
        {(this.item.typeData || []).map((e, i) => (
          <Tabs.TabPane tab={e.deliveryCompany} key={i}></Tabs.TabPane>
        ))}
      </Tabs>
    )
  }
}
</script>
