<template>
  <a-config-provider :locale="zhCN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import 'view-design/dist/styles/iview.css'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data() {
    return {
      zhCN
    }
  }
}
</script>
<style>
#app {
  overflow: hidden;
}
</style>
