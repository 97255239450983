<template>
  <div :class="wrpCls">
    <!-- <a-input
      v-if="showInput && !isMobile"
      placeholder="搜索"
      class="header-input"
    /> -->
    <avatar-dropdown v-if="currentUser.name" :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
    <!-- <select-lang :class="prefixCls" /> -->
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import SelectLang from '@/components/SelectLang'
import { getAction } from '@/command/netTool'

export default {
  name: 'right-content',
  components: {
    AvatarDropdown,
    SelectLang
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showMenu: true,
      showInput: true,
      currentUser: {},
      username: null,
      avatar: null
    }
  },
  computed: {
    wrpCls() {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${this.isMobile || !this.topMenu ? 'light' : this.theme}`]: true,
        'header-right-main': true
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (document.body.clientWidth < 660) {
        this.showInput = false
      } else {
        if (this.showInput == false) {
          this.showInput = true
        }
      }
    })
    getAction('/admin/user/info', {}, '/bpi').then(({ data }) => {
      const { sysUser, permissions } = data
      const { realName, avatar } = sysUser
      this.username = realName
      this.avatar = avatar
      localStorage.setItem('userAuth', JSON.stringify(permissions))
      this.getUserInfo()
    })
  },
  methods: {
    getUserInfo() {
      // 显示登录的用户
      setTimeout(() => {
        this.currentUser = {
          name: this.username ? this.username : '管理员',
          avatar: this.avatar
            ? this.avatar
            : 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png'
        }
      }, 1500)
    }
  }
}
</script>
<style lang="less" scoped>
.header-right-main {
  width: auto !important;
}
.header-input {
  border: none !important;
  outline: none !important;
  border-width: 0px;
  appearance: none;
  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
  }
}
</style>
